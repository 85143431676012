<template>
    <div>
      <v-alert type="error">{{msg}}</v-alert>
    </div>
  </template>
  <script>
  const CryptoJS = require("crypto-js")
  export default {
    data: () => ({
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      params: null,
      msg: ""
    }),
    mounted() {
      this.receiptno = this.$route.query.receipt;
      if (this.receiptno == "failed") {
        this.msg = "Dear Student, " +
          "After doing payment, if receipt is not generated and amount is deducted from your bank because of any reasons then nothing to worry, " +
          "Email whatever bank transaction details you have to account section, " +
          "they will generate your receipt and you will be able to see the receipts on your portal on ERP.";
      } else {
        this.$router.push({
          name: "pcu-admission-receipt",
          query: { 
            rec_no: this.encrypt(this.receiptno),
          }
        });
      }
    },
  
    methods: {
      encrypt (src) {
          const passphrase = '987654'
          var data =  CryptoJS.AES.encrypt(src, passphrase).toString();
          return data
      },
    }
  };
  </script>